<template>
    <div>
        <router-link class="router-link-cell" v-bind="params.routerlink">{{value}}</router-link>
        <span v-if="params.icon">
            <mercur-tooltip>
                <template #label>{{ params.tooltip }}</template>
                <i :class="params.icon"></i>
            </mercur-tooltip>
        </span>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'RouterLinkCell',
    computed: {
        value () {
            return this.params.valueFormatted ? this.params.valueFormatted : this.params.value
        },
    },
})
</script>

<style lang="scss" scoped>
    .router-link-cell {
        text-decoration: underline;
    }
</style>
