class ApiServerSideDataSource {
    constructor ({ $api, url, params, responseHandler, getFilterModel, errorHandler = console.warn }) {
        this.$api = $api
        this.params = params
        this.url = url
        this.responseHandler = responseHandler
        this.getFilterModel = getFilterModel
        this.errorHandler = errorHandler
    }

    getRows ({ request, successCallback, failCallback }) {
        this.$api.post(this.url, { request }).then(({ data }) => {
            this.responseHandler(successCallback, data)
        }).catch((error) => {
            this.errorHandler(error)
            failCallback(error)
        })
    }
}

export default ApiServerSideDataSource
