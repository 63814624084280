<template>
    <div class="align-right">
        <component :is="isDropdown ? 'mercur-menu' : 'div'" appendToBody>
            <template v-if="isDropdown">
                <mercur-button v-bind="attr" class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                <div slot="dropdown">
                    <template v-for="(actionButton, key) in items">
                        <mercur-item v-if="to(actionButton) && !isHidden(actionButton)" v-bind="actionButton.attr" :key="`linki-${key}`" :to="to(actionButton)">
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                        </mercur-item>
                        <mercur-item v-else-if="!to(actionButton) && !isHidden(actionButton)" v-bind="actionButton.attr" @click.native="onClick(actionButton)" :key="`btn-${key}`">
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                        </mercur-item>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="d-inline-block" v-for="(actionButton, key) in items" :key="key">
                    <mercur-tooltip>
                        <template #label>{{actionButton.tooltip}}</template>
                        <mercur-button
                            class="btn btn-icon"
                            :to="to(actionButton)"
                            @click.native="onClick(actionButton)"
                            v-if="!isHidden(actionButton)"
                            :disabled="isDisabled(actionButton)"
                            v-bind="actionButton.attrs"
                        >
                            <span v-if="actionButton.text">{{actionButton.text}}</span>
                            <i v-if="actionButton.icon" :class="actionButton.icon"></i>
                        </mercur-button>
                    </mercur-tooltip>
                </div>
            </template>
        </component>

    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'ActionsCell',
    computed: {
        items () {
            return this.params.items
        },
        attr () {
            return this.params.attr
        },
        isDropdown () {
            return this.params.type === 'dropdown'
        },
    },
    methods: {
        to (actionButton) {
            if (actionButton.to && {}.toString.call(actionButton.to) === '[object Function]') {
                return actionButton.to(this.params)
            }
            return actionButton.to
        },
        onClick (actionButton) {
            if (actionButton.onClick) {
                actionButton.onClick(this.params)
                return
            }
            return () => {}
        },
        isHidden (action) {
            if (action.isHidden) {
                return action.isHidden(this.params)
            }
            return false
        },
        isDisabled (action) {
            if (action.isDisabled) {
                return action.isDisabled(this.params)
            }
            return false
        },

    },
})
</script>

<style lang="scss" scoped>
    .align-right {
        text-align: right;
    }
    .icon div {
        display: inline-block;
    }
    .btn-icon {
        padding: 0;
        width: 25px;
        height: 25px;
        border-radius: 0;

        &:hover {
            background: none;
        }

        i {
            font-size: 15px;
            vertical-align: middle;
        }
    }
</style>
