<script>
/**
     * Available props
     * {
     *     {url} (String) API endpoint to call for fetching data.
     *     {options} (object) Configuration options
     *          ...
     *          {actions} (array of objects)
     *              Example:
     *              {
     *                  icon: 'remove_red_eye',
     *                  text: 'View',
     *                  to: params => ({
     *                      name: 'SupplierProducts',
     *                      params: {
     *                          supplierId: params.data.supplierId,
     *                      },
     *                  }),
     *              },
     *              {
     *                  icon: 'edit',
     *                  text: 'Edit',
     *                  to: '/edit',
     *              },
     *              {
     *                  icon: 'custom',
     *                  text: 'Custom actions',
     *                  onClick: function (params) {
     *                      this.customAction(params.entityId)
     *                  }
     *              },
     *          ...
     *
     * }
     */
import ActionsCell from '@/components/elements/table/ActionsCell'
import RouterLinkCell from '@/components/elements/table/RouterLinkCell'
import StatusChip from '@/components/utils/StatusChip'
import SimpleCellRenderer from '@/components/utils/AgGridSimpleCellRenderer'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'

export default {
    name: 'DataTable',
    components: {
        StatusChip,
    },
    data () {
        return {
            columnDefs: [],
            gridOptions: null,
            filterModel: {},
            isFetchingTableData: false,
            defaultOptions: {
                isSelectable: false,
                pagination: true,
                paginationPageSize: 50,
                paginationType: 'native',
                quickSearchDelay: 500,
                quickSearchColumns: [],
                rowSelection: 'multiple',
                suppressCellSelection: true,
                suppressRowClickSelection: true,
                suppressPropertyNamesCheck: true,
                elementTag: 'div',
                sortModel: {},
                headerHeight: 46,
                autoHeight: true,
            },
            quickSearchTimerId: null,
        }
    },

    props: {
        url: null,
        items: {
            type: Array,
            default: () => [],
        },
        options: {
            required: false,
            type: Object,
            default: () => ({}),
        },
        quickSearch: {
            required: false,
            type: String,
            default: null,
        },
        selectedRows: {
            required: false,
            type: Array,
            default: () => [],
        },
    },

    watch: {
        quickSearch: {
            handler (value) {
                if (!this.mergedOptions.quickSearchColumns.length) {
                    return
                }
                if (this.quickSearchTimerId) {
                    clearTimeout(this.quickSearchTimerId)
                }
                this.quickSearchTimerId = setTimeout(() => {
                    if (!value) {
                        this.gridApi.setFilterModel(null)
                        return
                    }

                    const filterModel = this.mergedOptions.quickSearchColumns.reduce((accum, k) => {
                        accum[k] = {
                            type: 'contains',
                            filter: value,
                            filterType: 'text',
                        }
                        return accum
                    }, {})

                    this.gridApi.setFilterModel(filterModel)

                    this.quickSearchTimerId = null
                }, this.mergedOptions.quickSearchDelay)
            },
        },
        'mergedOptions.columns': {
            handler (value) {
                this.columnDefs = []
                this.generateColumns()
                this.generateRowActionsCell()
            },
            deep: true,
        },
        url () {
            this.applyDataSource()
        },
    },

    computed: {
        localUrl () {
            if (this.url) {
                return this.url
            }

            return this.params && this.params.url
        },
        localOptions () {
            if (Object.keys(this.options).length) {
                return this.options
            }

            return this.params && this.params.options
        },
        rowModel () {
            if (this.localUrl) {
                return 'serverSide'
            }
            return 'clientSide'
        },
        mergedOptions () {
            return {
                ...this.defaultOptions,
                ...this.localOptions,
            }
        },
    },

    methods: {
        onGridReady () {
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            if (this.mergedOptions.pagination && this.mergedOptions.paginationType !== 'native') {
                this.onPaginationChanged()
            }

            if (this.mergedOptions.sortModel) {
                this.gridApi.setSortModel(this.mergedOptions.sortModel)
            }

            if (this.mergedOptions.filterModel) {
                this.gridApi.setFilterModel(this.mergedOptions.filterModel)
            }

            if (this.rowModel === 'clientSide') {
                return
            }

            this.applyDataSource()
        },
        applyDataSource () {
            this.isFetchingTableData = true
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.localUrl,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    this.isFetchingTableData = false

                    if (data === null || data.length === 0) {
                        this.gridApi.showNoRowsOverlay()

                        return
                    }
                    const totalRows = data[0].totalRows

                    if (this.mergedOptions.onDataReceived) {
                        this.mergedOptions.onDataReceived(data, totalRows)
                    }

                    successCallback(data, totalRows)

                    setTimeout(() => {
                        this.gridApi.sizeColumnsToFit()
                    }, 10)
                },
            })

            this.gridApi.setServerSideDatasource(this.serverSideDatasource)
        },
        generateColumns () {
            Object.entries(this.mergedOptions.columns).forEach(([headerName, item]) => {
                let column = {
                    headerName,
                    field: item.field,
                    sortable: item.sortable,
                    ...item,
                }

                if (Array.isArray(item.field)) {
                    column.valueFormatter = (params) => item.field.map(fieldName => {
                        if (fieldName.includes('.')) {
                            let value = params.data
                            fieldName.split('.').forEach((key) => {
                                value = value[key]
                            })
                            return value
                        }
                        return params.data[fieldName]
                    }).join(' ')
                    column.field = item.field[0]
                }

                if (item.link) {
                    column.cellRendererFramework = RouterLinkCell
                    column.cellRendererParams = (params) => {
                        const routerlink = {
                            to: item.link(params.value, params.data),
                        }

                        if (item.attrs) {
                            Object.entries(item.attrs).forEach(([key, value]) => {
                                routerlink[key] = value
                            })
                        }

                        return {
                            routerlink,
                            icon: item.icon && item.icon(params.data),
                            tooltip: item.tooltip && item.tooltip(params.data),
                        }
                    }
                } else if (item.attrs) {
                    column.cellRendererParams = {
                        ...(column.cellRendererParams || {}),
                        attrs: item.attrs,
                    }
                }

                if (item.statusChip === true) {
                    column.cellRendererFramework = StatusChip
                }

                this.columnDefs.push(column)
            })

            if (this.mergedOptions.isSelectable) {
                this.columnDefs[0].checkboxSelection = true
            }
        },
        generateRowActionsCell () {
            if (!this.mergedOptions.actions) {
                return
            }

            let width = 80
            if (this.mergedOptions.actions.type !== 'dropdown') {
                width = this.mergedOptions.actions.items.length * 56
            }

            this.columnDefs.push({
                headerName: '',
                cellRendererFramework: ActionsCell,
                cellRendererParams: () => this.mergedOptions.actions,
                suppressMenu: true,
                suppressColumnFilter: true,
                sortable: false,
                pinned: 'right',
                width: width,
                maxWidth: width,
                cellClass: 'c-standard-table__actions-cell',
            })
        },
        refreshGrid () {
            this.gridOptions = {
                ...this.gridOptions,
                ...this.mergedOptions,
            }
            this.gridApi.purgeServerSideCache()
        },
        onModelUpdated (params) {
            params.api.sizeColumnsToFit()
        },
        rowClicked (params) {
            this.$emit('rowClicked', params.data)
        },
        applyFilter (filter) {
            this.gridApi.setFilterModel(filter)
        },
        getFilter () {
            return this.gridApi.getFilterModel()
        },
        toPreviousPage () {
            this.gridApi.paginationGoToPreviousPage()
        },
        toNextPage () {
            this.gridApi.paginationGoToNextPage()
        },
        onPaginationChanged () {
            if (this.gridApi) {
                this.$emit('paginationSet', {
                    currentPage: this.gridApi.paginationGetCurrentPage() + 1,
                    totalPages: this.gridApi.paginationGetTotalPages(),
                    totalRows: this.gridApi.paginationGetRowCount(),
                })
            }
        },
        onSelectionChanged () {
            this.$emit('update:selectedRows', this.gridApi.getSelectedRows())
        },
    },

    beforeMount () {
        this.gridOptions = {
            ...this.mergedOptions,
            suppressPaginationPanel: this.mergedOptions.paginationType !== 'native',
            defaultColDef: {
                cellRendererFramework: SimpleCellRenderer,
                sortable: false,
                autoHeight: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['equals', 'notEqual'],
                    defaultOption: 'equals',
                    applyButton: true,
                    newRowsAction: 'keep',
                    clearButton: true,
                    caseSensitive: true,
                    suppressAndOrCondition: true,
                },
                menuTabs: ['generalMenuTab', 'filterMenuTab'],
                resizable: true,
                cellClass: `c-standard-table__cell ${this.mergedOptions.customCellClass}`,
                ...this.mergedOptions['defaultColDef'],
            },
        }
        this.generateColumns()
        this.generateRowActionsCell()
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi
    },
}
</script>
