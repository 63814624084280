<template>
    <div class="grid-wrapper c-standard-table" :class="{'c-standard-table--min-height': isDefaultSize}" :is="mergedOptions.elementTag">
        <ag-grid-vue class="ag-theme-balham c-standard-table__grid"
                    v-bind="attrs"
                    :class="{ 'c-standard-table__grid--fetching' : !!isFetchingTableData }"
                    style="height: 100%;"
                    :columnDefs="columnDefs"
                    :rowModelType="rowModel"
                    :rowData="items"
                    :gridOptions="gridOptions"
                    @model-updated="onModelUpdated"
                    @grid-ready="onGridReady"
                    @pagination-changed="onPaginationChanged"
                    @revoked="refreshGrid"
                    @rowClicked="rowClicked"
                    @selection-changed="onSelectionChanged"
                    @cell-value-changed="onCellValueChanged"
                    :ref="(params && params.ref) && params.ref"
        >
        </ag-grid-vue>
        <div class="table-actions" v-if="params && params.tableActions && params.tableActions.length">
            <mercur-button class="btn btn-primary" v-for="(button, index) in params.tableActions" :key="index" @click.native="button.action">{{ button.text }}</mercur-button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import DataTable from '@/components/elements/DataTable'
export default Vue.extend({
    name: 'MerchantDataTable',
    mixins: [DataTable],
    components: { AgGridVue },
    data () {
        return {
            params: null,
        }
    },
    props: {
        isNotApplicationBound: {
            type: Boolean,
            default: false,
        },
        isDefaultSize: {
            default: true,
        },
        attrs: {
            type: Object,
            default: null,
        },
    },
    methods: {
        onCellValueChanged (params) {
            this.$emit('cellChanged', params)
        },
        refreshCells () {
            this.gridApi.refreshCells()
        },
        setPageSize (value) {
            this.gridApi.paginationSetPageSize(Number(value))
        },
        listen () {
            if (!this.isNotApplicationBound) {
                this.refreshGrid()
                this.gridApi.hideOverlay()
            }
        },
    },
    mounted () {
        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
})
</script>

<style lang="scss" scoped>
    .table-actions {
        width: calc(100% - 350px);
        height: 54px;
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
    }
</style>
