<template>
    <mercur-chip class="chip" v-bind="params.attrs" :class="classNames"><slot><span>{{params.value}}</span></slot></mercur-chip>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name: 'StatusChip',
    props: {
        color: {},
    },
    data () {
        return {
            styleMap: {
                red: ['error', 'failed', 'archived'],
                orange: ['warning', 'caution', 'out of date', 'outdated', 'unavailable'],
                green: ['success', 'complete', 'done', 'finished', 'active', 'up to date', 'available'],
                blue: ['pending', 'in progress', 'waiting', 'running', 'sent', 'open', 'generating', 'awaiting', 'importing'],
                gray: ['canceled'],
                purple: [],
            },
            params: {},
        }
    },
    computed: {
        sourceText () {
            const text = (this.$slots.default ? this.$slots.default[0].text : this.params.value) || ''
            return text.toLowerCase()
        },
        classNames () {
            if (this.color) {
                return this.color
            }

            const classNames = []

            if (!this.sourceText) {
                return
            }

            Object.entries(this.styleMap).some(([color, texts]) => {
                return texts.some(text => {
                    if (this.sourceText.includes(text)) {
                        classNames.push(color)

                        return true
                    }
                })
            })

            return (classNames.length ? classNames : ['orange'])
        },
    },
})
</script>

<style lang="scss" scoped>
    .chip {
        &.red {
            background-color: #f33a3a;
        }

        &.green {
            background-color: #379040;
        }

        &.yellow,
        &.orange {
            background-color: #ff8431;
        }

        &.blue {
            background-color: #5867d4;
        }

        &.purple {
            background-color: #9a58d4;
        }
    }
</style>
