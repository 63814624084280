<template>
    <span v-bind="params.attrs">{{value}}</span>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
    computed: {
        value () {
            return this.params.valueFormatted ? this.params.valueFormatted : this.params.value
        },
    },
})
</script>
